<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold mr-3">Incomplete Orders</h2>
      </div>
      <div class="col-span-1">
        <search-form
          placeholder="Search user name, merchant name...."
          class="mb-5"
          v-model="data.query"
          @submit="$refs.table.loadAjaxData()"
        />
      </div>
    </div>
    <datatable
      :url="data.url"
      :ajax="true"
      :ajax-pagination="true"
      :columns="data.columns"
      :actions="data.actions"
      :query="data.query"
      :fillable="true"
      :on-click="click"
      ref="table"
    />

    <modals-user
      :selected-user="data.selectedUser"
      ref="userModal"
      @close="userModalClosed"
    />

    <modal ref="deleteConfirmModal" class-name="w-11/12 md:w-4/12 xl:w-3/12">
      <div class="mb-5">
        <div class="text-xl font-bold mb-3">Mark As Paid?</div>

        <div class="font-light text-gray-700 text-sm">
          Are you sure, you want to perform this action?
        </div>
      </div>

      <div class="flex items-center">
        <button
          type="button"
          class="btn btn-blue"
          @click.prevent="$refs.deleteConfirmModal.close"
        >
          Cancel
        </button>

        <button
          type="button"
          class="btn btn-red ml-3"
          @click.prevent="markOrderAsPaid"
          :disabled="data.loading"
        >
          <sm-loader v-if="data.loading" class="sm-loader-white" />
          <span v-else>Proceed</span>
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: this.$options.resource([], {
        selectedOrder: null,
        selectedUser: null,
        url: `${this.$baseurl}/admin/merchants/incomplete-orders`,
        query: '',
        columns: [
          {
            name: 'payment_method',
            th: 'Source',
            render: (order) =>
              this.$options.filters.fromSlug(order?.payment_method),
          },
          {
            name: 'user',
            th: 'User',
            render: ({ user }) => `${user?.name} ${user?.last_name}`,
          },
          {
            name: 'merchant',
            th: 'Merchant',
            render: ({ merchant }) => merchant?.business_name,
          },
          {
            name: 'user.phone_no',
            th: 'User Phone No',
            render: ({ user }) => user?.phone_no,
          },
          {
            name: 'user.profile.standard_loan_limit',
            th: 'User Loan Limit',
            render: ({ user }) =>
              this.$options.filters.formatAmount(
                user?.profile?.standard_loan_limit
              ),
          },
          {
            name: 'user.profile.credit_limit',
            th: 'User Credit Limit',
            render: ({ user }) =>
              this.$options.filters.formatAmount(user?.profile?.credit_limit),
          },
          {
            name: 'user.status',
            th: 'Status',
            render: ({ user }) => this.statusBadge(user?.status),
          },
          {
            name: 'progress',
            th: 'Progress',
            render: ({ user }) => {
              return this.progressIcons(user);
            },
            sortable: false,
          },
          {
            name: 'order_description',
            th: 'Order Description',
          },
          {
            name: 'order_amount',
            th: 'Order Amount',
            render: (order) =>
              this.$options.filters.formatAmount(order?.order_amount),
          },
          {
            name: 'tenure',
            th: 'Tenure',
            render: (order) => order?.tenure ?? '---',
          },
          {
            name: 'product_type',
            th: 'Product Type',
            render: (order) => {
              const { fromSlug, sentenceCase } = this.$options.filters;
              return sentenceCase(fromSlug(order?.product_type)) ?? 'BNPL';
            },
          },
          {
            name: 'created_at',
            th: 'Created At',
            render: (order) =>
              this.$moment(order?.created_at).format('dddd, MMMM Do YYYY h:sa'),
          },
        ],
        actions: [
          {
            text: 'mark order as paid',
            class:
              'border-blue-500 text-blue-500 rounded-sm px-4 py-2 hover:bg-gray-200',
            action: this.confirm,
            disabled: (order) => order.status === 'completed' || !order?.tenure,
          },
          {
            text: 'Delete Order',
            class: 'border-red-500 text-red-600 rounded-sm px-4 py-2',
            action: this.deleteOrder,
          },
        ],
      }),
    };
  },
  methods: {
    click({ user }) {
      this.data.selectedUser = user;
      this.$nextTick(() => this.$refs.userModal.open());
    },
    userModalClosed() {
      this.data.selectedUser = null;
    },
    async markOrderAsPaid() {
      this.data.loading = true;
      await this.sendRequest(
        `admin.merchants.transactions.markOrderAsPaid`,
        this.data.selectedOrder?.id,
        {
          success: async () => {
            await this.$refs.table.loadAjaxData();
            this.$nextTick(() => {
              this.$success({ title: 'Mark as paid successful' });
              this.$refs.deleteConfirmModal?.close();
            });
          },
          error: (error) =>
            this.$error({ title: error?.response?.data?.message }),
        }
      ).finally(() => (this.data.loading = false));
    },
    async deleteOrder({ id }) {
      await this.sendRequest(
        `admin.merchants.transactions.deleteIncompleteOrder`,
        id,
        {
          success: () => this.$nextTick(() => this.$refs.table.loadAjaxData()),
          error: (error) =>
            this.$error({ title: error?.response?.data?.message }),
        }
      );
    },
    confirm(order) {
      this.data.selectedOrder = order;
      this.$nextTick(() => this.$refs.deleteConfirmModal?.open());
    },
  },
};
</script>
